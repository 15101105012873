<template>
  <div>
    <a-table
      :columns="columns"
      :data-source="detailListdata"
      @change="onChangeSort"
      :pagination="false"
      :rowKey="(record) => record.item_id"
      :loading="loading"
    >
      <div slot="cover" slot-scope="text, record" class="video_title">
        <div class="cover_box">
          <img :src="text" alt="" />
        </div>
        <span>{{ record.title }}</span>
      </div>
      <span slot="share_url" slot-scope="share_url">
        <a-icon
          type="play-circle"
          @click="() => openVideo(share_url)"
          style="font-size: 20px; color: #4a58f3"
        />
      </span>
      <span slot="comment_count" slot-scope="comment_count">
        {{ comment_count | changeNum }}
      </span>
      <span slot="digg_count" slot-scope="digg_count">
        {{ digg_count | changeNum }}
      </span>
      <span slot="share_count" slot-scope="share_count">
        {{ share_count | changeNum }}
      </span>
    </a-table>
    <!-- 分页配置 -->
    <base-pagination
      :currentPage="detailPagination.current"
      :pageSize="detailPagination.page_size"
      :total="detailPagination.total"
      @onChange="onChange"
      @onShowSizeChange="onChange"
    >
    </base-pagination>
  </div>
</template>
<script>
// import { createNamespacedHelpers } from 'vuex'
// const { mapState, mapMutations, mapActions } = createNamespacedHelpers('hot_topic')
const columns = [
  {
    title: "视频",
    dataIndex: "cover",
    scopedSlots: { customRender: "cover" },
  },
  {
    title: "点赞数",
    align: "center",
    dataIndex: "digg_count",
    sorter: true,
    scopedSlots: { customRender: "digg_count" },
  },
  {
    title: "评论数",
    align: "center",
    dataIndex: "comment_count",
    sorter: true,
    scopedSlots: { customRender: "comment_count" },
  },
  {
    title: "分享数",
    align: "center",
    dataIndex: "share_count",
    scopedSlots: { customRender: "share_count" },
    sorter: true,
  },
  {
    title: "操作",
    align: "center",
    dataIndex: "share_url",
    scopedSlots: { customRender: "share_url" },
  },
];

export default {
  data() {
    return {
      columns,
      type: "",
      order: "",
      loading: false,
      detailListdata: [],
      detailPagination: {
        page_size: 5,
        current: 1,
        total: 0,
      },
    };
  },
  computed: {},
  props: {
    tableId: String,
  },
  methods: {
    // ...mapActions(['detailList']),
    /*
     * 热词详情列表
     * order_by	排序字段(默认点赞) 1:点赞数,2:分享数,3:评论数
     * order	正序倒序(默认倒序) 倒序desc 正序asc
     */
    getList(page, page_size, order, order_by) {
      const params = {
        sentence: this.tableId,
        page,
        page_size,
        order_by: order_by || "",
        order: order || "desc",
      };
      this.$api.core.Polymers.topicVideo(params)
        .then((res) => {
          this.loading = false;
          if (res.list) {
            this.detailListdata = res.list;
            this.detailPagination = res.pagination;
          }
        })
        .catch((res) => {
          console.log(res);
          this.loading = false;
        });
    },
    // 分页事件-修改当前页码、修改当前页数
    onChange(current, pageSize) {
      this.currentPage = current;
      this.pageSize = pageSize;
      this.getList(current, pageSize, this.order, this.type);
    },
    // 排序
    onChangeSort(pagination, filters, sorter) {
      switch (sorter.columnKey) {
        case "digg_count":
          this.type = 1;
          break;
        case "comment_count":
          this.type = 3;
          break;
        case "share_count":
          this.type = 2;
          break;
        default:
          break;
      }
      this.order = sorter.order === "ascend" ? "asc" : "desc";
      this.getList(
        this.detailPagination.current,
        this.detailPagination.page_size,
        this.order,
        this.type
      );
    },
    openVideo(url) {
      if (url) {
        window.open(url);
      } else {
        this.$message.warning("链接不存在");
      }
    },
  },
  mounted() {
    this.getList(1, 5);
  },
};
</script>
<style lang="less" scoped>
.video_title {
  display: flex;
  align-items: center;
  .cover_box {
    height: 118px;
    width: 89px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-right: 10px;
    img {
      max-height: 100%;
      max-width: 100%;
      border-radius: 10px;
    }
  }
}
</style>
