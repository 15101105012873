<template>
  <div class="box_chart">
    <div :id="id" :style="styleObject" />
    <div class="tips_box" v-if='this.$route.query.hot_level'>
      <p class="tips_title">近24小时上升</p>
      <p class="tips_content">{{this.$route.query.hot_level | changeNum}}</p>
    </div>
  </div>

</template>
<script>

import echarts from 'echarts/lib/echarts';
import 'echarts/lib/chart/line'
import 'echarts/lib/component/tooltip'
import resize from './mixins/resize'
export default {
  mixins: [resize],
  props: {
    id: {
      type: String,
      default: 'line_chart'
    },
    boxStyle: {
      type: Object,
      default: () => {
        return { width: '100', height: '300px' }
      }
    },
    chartData: {
      type: Object,
      default: () => {
        return {
          xAxisData: [],
          yAxisData: []
        }
      }
    },
    // lineareStyle: {
    //   type: Object,
    //   default: () => {
    //     return {
    //       smooth: false, // 曲线是否平滑，
    //       color: ['#4A58F3', '#6395f9'], // 线条颜色
    //       opacity: 0.2,
    //       type: 'line', // 设置图形
    //       boundaryGap: false
    //     }
    //   }
    // },
    // legendStyle: {
    //   type: Object,
    //   default: () => {
    //     return {
    //       show: false
    //     }
    //   }
    // }

  },
  data() {
    return {
      chart: null
    }
  },
  computed: {
    styleObject: function() {
      const obj = this.boxStyle
      for (const attr in this.boxStyle) {
        // var current = getComputedStyle(this.boxStyle)[attr]
        // 提取单位,若存在单位，得到数组。若不存在单位，得到%
        var unit = this.boxStyle[attr].match(/[a-z]+$/)
        unit = unit ? unit[0] : '%'

        obj[attr] = unit !== '%' ? this.boxStyle[attr] : this.boxStyle[attr] + unit
      }
      return obj
    }
  },
  watch: {
    chartData: function(val) {
      this.initChart()
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.initChart()
    })
  },
  beforeDestroy() {
    if (!this.chart) {
      return
    }
    // this.charts.dispose()// 销毁实例
    this.charts = null
  },
  methods: {
    initChart() {
      console.log('this.$route.params.hot_level',this.$route.query.hot_level)
      this.chart = echarts.init(document.getElementById(this.id))
      const series = []
      // const arr = this.chartData.yAxisData
      // for (const key in arr) {
      //   series.push(
      //     {
      //       data: arr[key].data,
      //       type: typeof (this.lineareStyle.type) === 'string' ? 'line' : this.lineareStyle.type[key],
      //       smooth: this.lineareStyle.smooth,
      //       areaStyle: {
      //         opacity: this.lineareStyle.opacity,
      //         type: 'default'
      //       },
      //       barWidth: '25%',
      //       name: this.legendStyle.data[key]
      //     })
      // }
      this.chart.setOption({
          xAxis: {
            type: "category",
            boundaryGap: false,
            data: this.chartData.xAxisData,
          },
          yAxis: {
            type: "value",
            splitNumber: 4, 
            axisTick: {
              inside: false,
            },
            axisLabel: {
              show: true,
                textStyle: {
                  color: '#17233d',  //更改坐标轴文字颜色
                  fontSize : 10      //更改坐标轴文字大小
                }
            },
            axisLine: {
              show: false,
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: "dashed",
              },
            },
          },
          tooltip: {
            show: true,
            trigger: "axis",
            axisPointer: {
              type: "cross",
              label: {
                backgroundColor: "#6a7985",
              },
            },
          },
          grid: {
            left: "5%",
            top: "20",
            right: "8%",
            bottom: "20",
            containLabel: true,
          },
          series: [
            {
              data: this.chartData.yAxisData,
              smooth: true,
              type: "line",
              itemStyle: {
                color: "#4A58F3",
              },
              areaStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: "rgba(74,88,243,0.4)",
                  },
                  {
                    offset: 1,
                    color: "rgba(74,88,243,0.1)",
                  },
                ]),
              },
            },
          ],
      })
    }
  }
}
</script> 
<style lang="less" scoped>

.box_chart{
  position: relative;
  .tips_box{
    width:150px;
    position: absolute;
    left: 150px;
    top:22px;
    background: #fefefe;
    opacity: 0.6;
    border-radius: 8px;
    border: 1px solid #d5d5d5;
    .tips_title{
      margin:0px;
      font-size: 14px;
      color:#000;
      padding:8px 0 4px 8px;
    }
    .tips_content{
      text-align: right;
      font-size: 18px;
      font-weight: bold;
      color:rgb(223, 35, 14);
      padding:4px 8px 8px 0;
      margin: 0;
    }
  }
}

</style>
