<template>
  <a-card>
    <div class="head_box">
      <p class="title">{{ sentence }}</p>
      <div class="hot_value">
        热度值：<span>{{ hotTopicMessage.hot_level | changeNum }}</span>
      </div>
    </div>
    <div class="content">
      <div class="about_video">
        <div class="cnt_left">
          <div class="hot_value mgn_r">
            相关视频：<span>{{ hotTopicMessage.video_number }}</span>
          </div>
          <div class="hot_value mgn_r">
            上榜时间：<span>{{ hotTopicMessage.ctime }}</span>
          </div>
          <div class="hot_value mgn_r">
            距今：<span>{{ hotTopicMessage.before_time }}个小时</span>
          </div>
        </div>
        <div class="cnt-right">
          <!-- <p>TOP1</p>
          <span>{{ 43213 | changeNum }}人在看</span> -->
        </div>
      </div>
    </div>
    <!-- 图表 -->
    <SimpleLineChart
      :id="'linechant'"
      :box-style="{ width: '100', height: '450' }"
      :chart-data="tendecyList"
    />
    <p class="title">相关视频 效果</p>
    <div class="content">
      <div class="about_effect">
        <div class="mgn_r">
          <p>最高点赞</p>
          <span>{{ hotTopicMessage.digg_count_max | changeNum }}</span>
        </div>
        <div class="mgn_r">
          <p>最高评论</p>
          <span>{{ hotTopicMessage.comment_count_max | changeNum }}</span>
        </div>
        <div class="mgn_r">
          <p>最高转发</p>
          <span>{{ hotTopicMessage.for_ward_count_max | changeNum }}</span>
        </div>
        <div class="mgn_r">
          <p>总点赞</p>
          <span>{{ hotTopicMessage.digg_count_total | changeNum }}</span>
        </div>
        <div class="mgn_r">
          <p>总评论</p>
          <span>{{ hotTopicMessage.comment_count_total | changeNum }}</span>
        </div>
        <div class="mgn_r">
          <p>总转发</p>
          <span>{{ hotTopicMessage.share_count_total | changeNum }}</span>
        </div>
      </div>
      <!-- <div style="padding:16px 0">
        <a-radio-group default-value="a" button-style="solid">
          <a-radio-button value="a"> 相关视频 </a-radio-button>
          <a-radio-button value="b" disabled> 高赞评论 </a-radio-button>
        </a-radio-group>
      </div> -->
      <!-- 相关视频列表 -->
      <VideoTable :table-id="sentence" />
    </div>
  </a-card>
</template>
<script>
import VideoTable from "./components/VideoTable";
import SimpleLineChart from "@/components/SimpleLine";
// import { createNamespacedHelpers } from "vuex";
// const { mapState, mapMutations, mapActions } =
//   createNamespacedHelpers("hot_topic");
export default {
  components: {
    VideoTable,
    SimpleLineChart,
  },
  data() {
    return {
      sentence: this.$route.query.id,
      loading: false,
      hotTopicMessage: {},
      tendecyList: [],
    };
  },
  computed: {
    // ...mapState({
    //   loading: (state) => state.hotTopicList,
    //   tendecyList: (state) => state.tendecyList,
    //   hotTopicMessage: (state) => state.hotTopicMessage,
    // }),
  },
  methods: {
    // 初始化
    initData() {
      let payload = {
        sentence: this.sentence,
      };
      this.$api.core.Polymers.topicDetail(payload).then((res) => {
        this.hotTopicMessage = res;
      });
      this.$api.core.Polymers.topicChart(payload).then((res) => {
        this.tendecyList = this.disposeDataB(res);
      });
    },

    disposeDataB(data) {
      let arrY = data.map(({ hot_level }) => hot_level);
      let arrX = data.map(({ job_time }) => job_time);
      arrX = arrX.map((item) => {
        return item.slice(11);
      });
      data = {
        xAxisData: arrX,
        yAxisData: arrY,
      };
      return data;
    },
  },
  mounted() {
    this.initData();
  },
};
</script>
<style lang="less" scoped>
.mgn_r {
  margin-right: 38px;
}
.head_box {
  display: flex;
  justify-content: space-between;
}
.title {
  border-left: 4px solid #4a58f3;
  font-size: 18px;
  font-weight: bold;
  // margin: 16px 0 20px 44px;
  padding-left: 10px;
}
.content {
  padding: 0 20px 20px 20px;
}
.hot_value {
  font-size: 16px;
  color: #aaa;
  span {
    color: #000;
  }
}
.about_video {
  display: flex;
  justify-content: space-between;
  .cnt_left {
    display: flex;
  }
}
.cnt-right {
  p {
    display: inline-block;
    padding: 4px 6px;
    background: #f5a60b;
    color: #fff;
    font-weight: bold;
    border-radius: 8px;
    margin-right: 16px;
  }
  span {
    font-size: 16px;
    color: #000;
  }
}
.about_effect {
  display: flex;
  p {
    margin: 8px 0;
    font-size: 16px;
  }
  span {
    font-size: 22px;
    font-weight: bold;
    color: #4a58f3;
  }
}
</style>